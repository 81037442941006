import { CrmFilterOptions, CrmTableOptions } from '@/types/type';
import { loadPage } from '@/utils/publicMethods';
import { defineComponent, ref } from 'vue';
export default (Instance: any) => {
  const tableRef = ref();
  const filterOptions: CrmFilterOptions[] = [
    {
      type: 'input',
      prop: 'name',
      value: '',
      placeholder: '请输入联系人',
    },
    {
      type: 'input',
      prop: 'customerName',
      value: '',
      placeholder: '请输入所属客户',
    },
  ];
  const columnList: CrmTableOptions[] = [
    {
      label: '联系人',
      component: defineComponent({
        template: `
          <div class="crm-cell-link" @click="clickFn(row)">
          {{row.name}}({{row.sexName}})
          </div>
        `,
        props: ['row'],
        setup() {
          /** 编辑 */
          const clickFn = (row) => {
            Instance.setupState.setRowData(row);
            Instance.setupState.setFormType(3);
            Instance.setupState.showVisible(3);
          };
          return { clickFn };
        },
      }),
      prop: '',
      minWidth: 70,
    },
    {
      label: '所属客户',
      component: defineComponent({
        template: `
          <div class="crm-cell-link" @click="clickFn(row)">
          {{row.customerName}}
          </div>
        `,
        props: ['row'],
        setup() {
          /** 点击行 */
          const clickFn = (row) => {
            Instance.setupState.rowData.value = row;
            let type = 0;
            if (row.isDeleted) {
              type = 3;
            } else {
              if (row.isInPool) {
                type = 2;
              } else {
                type = 1;
              }
            }
            loadPage('ContactDetails', {
              id: row.customerId,
              isRecycle: false,
              type, // 1客户 2客户池 3回收站
              backFrom: '/contacts/contactsList',
            });
          };
          return { clickFn };
        },
      }),
      prop: '',
      minWidth: 130,
    },
    {
      label: '职位',
      prop: 'position',
      minWidth: 70,
    },
    {
      label: '联系电话',
      prop: 'telephone',
      minWidth: 70,
    },
    {
      label: '邮箱',
      prop: 'email',
      minWidth: 120,
    },
    {
      label: '微信',
      prop: 'weixin',
      minWidth: 80,
    },
    {
      label: '备注',
      prop: 'remark',
      minWidth: 100,
    },
    {
      label: '创建时间',
      prop: 'createdTime',
      minWidth: 100,
    },
    {
      label: '更新时间',
      prop: 'lastEditTime',
      minWidth: 100,
    },
  ];
  return { filterOptions, columnList, tableRef };
};
